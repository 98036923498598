import React from 'react';
import { graphql } from 'gatsby';
import { Homepage, Layout } from 'components';

const IndexPage = ({ data }) => (
  <Layout variant="home" data={data} locale="en">
    <Homepage data={data} locale="en" />
  </Layout>
);

export const query = graphql`
  query {
    datoCmsFooter(locale: { eq: "en" }) {
      instagramLink
      facebookLink
      twitterLink
      linkedinLink
      termsTitle
      terms
      termsOfServiceTitle
      termsOfService
      dataProtectionPolicyTitle
      dataProtectionPolicy
    }
    datoCmsHomepage(locale: { eq: "en" }) {
      paprworkLink
      title
      subtitle
      ctaTitle
      ctaLink
      onewayTitle
      onewaySubtitle
      onewaySubtitle2
      onewaySubtitle3
      onewayCtaTitle
      onewayCtaLink
      mainPicture {
        url
        alt
      }
      mobilePicture {
        url
        alt
      }
      benefitsTitle
      benefitsSubtitle
      benefits {
        id
        name
        description
        icon {
          url
        }
      }
      sections {
        title
        content
        layout
        contentNode {
          childMarkdownRemark {
            html
          }
        }
        image {
          url
          alt
        }
      }
      featureTitle
      featureSubtitle
      features {
        id
        name
        description
        icon {
          url
        }
      }
      partnerTitle
      certificationsTitle
      testimonialTitle
      testimonialSubtitle
      testimonials {
        name
        id
        company
        content
        avatar {
          url
        }
      }
      callToActionTitle
      callToActionButton
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
    }
    allDatoCmsPartner(filter: { locale: { eq: "en" } }) {
      edges {
        node {
          id
          name
          logo {
            url
          }
        }
      }
    }
    allDatoCmsAward(filter: { locale: { eq: "en" } }) {
      edges {
        node {
          id
          name
          logo {
            url
          }
        }
      }
    }
    allDatoCmsOneway(filter: { locale: { eq: "en" } }) {
      edges {
        node {
          id
          name
          order
          logo {
            url
          }
        }
      }
    }
    datoCmsSite(locale: { eq: "en" }) {
      globalSeo {
        fallbackSeo {
          description
          title
          twitterCard
          image {
            url
          }
        }
        siteName
        twitterAccount
      }
    }
  }
`;

export default IndexPage;
